import "./Footer.css"
import { FaFacebook } from "react-icons/fa";
import { FaYelp } from "react-icons/fa";
import { Link } from "react-router-dom";



function Footer(){
    return (
        <div id="Footer">
            <div className="left">
                <Link to="mailto:apexoverheaddoors2.0@gmail.com" className="email">apexoverheaddoors2.0@gmail.com</Link>
                <Link to="tel:6262557038" className="number">626-255-7038</Link>
            </div>
            <div className="divider">|</div>
            <div className="right">
                <Link to="https://www.facebook.com/profile.php?id=61564215692458&mibextid=LQQJ4d">
                    <FaFacebook size='25px'/>
                </Link>
                <Link to="https://www.yelp.com/">
                    <FaYelp size='25px'/>
                </Link>
            </div>


        </div>
    )
}

export default Footer;
