import './Nav.css';
import logo from './images/logo.png'
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useState, useEffect } from 'react';
import { FaArrowLeftLong } from "react-icons/fa6";


function Nav() {
    const [noNav, setNoNav] = useState(false);
    const [reviewForm, setReviewForm] = useState(false)
    const location = useLocation();

    function getLocation() {
        console.log("LOCATION:", location.pathname)
        console.log("noNav: ", noNav, "reviewForm: ", reviewForm)
        if (location.pathname === "/contact" || location.pathname === "/reviews") {
            setNoNav(true)
        } else if (location.pathname === "/reviews/new") {
            setReviewForm(true)
        } else {
            setNoNav(false)
            setReviewForm(false)
        }
    }

    useEffect(() => {
        getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])
    return(
        <div id="Nav">
            {reviewForm && (
                <div className="empty-nav"></div>
            )}
            { noNav && (
                <NavLink to="/">
                <div src={logo} className="return" alt="Apex Overhead Investment Logo">
                    <FaArrowLeftLong />
                    RETURN TO SITE
                </div>
            </NavLink>
            )}
            { !noNav && !reviewForm && (
                <>
                <NavLink to="/">
                <img src={logo} className="logo" alt="Apex Overhead Investment Logo" />

            </NavLink>
            <div className="nav-options">
                <HashLink smooth to="#Services">
                    <div className="services">SERVICES</div>
                </HashLink>
                <HashLink smooth to="#AboutUs">
                    <div className="about">ABOUT</div>
                </HashLink>
                <HashLink smooth to="#Testimonials">
                    <div className="reviews">TESTIMONIALS</div>
                </HashLink>
            </div>
            </>
            )}
        </div>
    )
}

export default Nav;
