import './Landing.css';
import { NavLink, Link} from "react-router-dom";
// import garage1 from "./images/pexels-jan-van-der-wolf-11680885-18408820.jpg";
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import image1 from "./images/IMG_1253 - Jesus Martinez.jpeg";
import image2 from "./images/IMG_1274 - Jesus Martinez.jpeg";
import image3 from "./images/IMG_1275 - Jesus Martinez.jpeg";
import image4 from "./images/IMG_1276 - Jesus Martinez.jpeg";
import image5 from "./images/IMG_1278 - Jesus Martinez.jpeg";

const slideshow = [
    {src: image1, alt: "garage door from outside"},
    {src: image2, alt: "garage door from inside"},
    {src: image3, alt: "garage door from inside"},
    {src: image4, alt: "garage door from inside"},
    {src: image5, alt: "garage door from inside"},
]



function Landing() {
    var settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
      };

    return (
        <>
        <div id="Landing">
            <div className='container'>
                <Slider className='placeholder' {...settings}>
                    {slideshow.map((slide) => {
                        console.log("slide: ", slide)
                        return (
                            <div className="slide-container">
                                <img src={slide.src} alt="trial" key={slideshow.indexOf(slide)}/>
                            </div>
                        )
                    })}
                </Slider>
                {/* <img src={garage1} alt="garage door cover" className="placeholder" /> */}
                <div className="sub">
                    <h1 className="slogan">We're here for your garage door needs.</h1>
                    <div className="buttons">
                        <NavLink to="/contact">
                            <button className="button">Schedule now</button>
                        </NavLink>
                        <Link to="tel:6262557038">
                            <button className="button">Call now</button>
                        </Link>
                    </div>
                </div>

            </div>
            </div>
                <div className="mission-statement">
                    <ScrollAnimation animateIn="fadeIn">
                        <h1>Our Mission</h1>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                        <div className= "description">
                            At Apex Overhead Investment Inc, we specialize in the expert repair and seamless installation of garage doors. With a commitment to quality craftsmanship and customer satisfaction, we offer a comprehensive range of services to meet your residential or commercial needs. Whether it's fixing a malfunctioning door, replacing worn-out parts, or installing a brand-new garage door system, our skilled technicians ensure every job is completed with precision and reliability. Trust Apex Overhead Investment, for all your garage door solutions, providing peace of mind and enhancing the functionality and aesthetics of your property.
                        </div>
                    </ScrollAnimation>
                </div>

        </>
    )
}

export default Landing;
