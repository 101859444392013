import "./Testimonials.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import SimpleSlider from "./Carousel";


function Testimonials() {
    return (
        <ScrollAnimation animateIn="fadeIn">
        <div id="Testimonials">
            <div className="header">
                <h1 className="container">
                    What Our Clients Say
                </h1>
                <div className="buttons">
                    <Link to="/reviews">
                        <button>View all</button>
                    </Link>
                    <Link to="/reviews/new">
                        <button>Leave a review</button>
                    </Link>
                </div>
            </div>
            {/* Simple slider contains the cards for reviews */}
            <SimpleSlider />
        </div>
        </ScrollAnimation>
    )
}

export default Testimonials;
