import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Nav from './Nav';
import FullPage from './FullPage.js';
import Footer from "./Footer.js";
import ContactForm from './ContactForm.js';
import ReviewForm from './ReviewForm.js';
import Reviews from './Reviews.js';


function App() {
  const domain = /https:\/\/[^/]+/;
  const basename = process.env.PUBLIC_URL.replace(domain, '');
  return (
    <BrowserRouter basename = {basename}>
        <Nav />
        <div className="router-container">
          <Routes>
            <Route path="/" element={<FullPage />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reviews/new" element={<ReviewForm />} />
          </Routes>
        </div>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
