import "./FullPage.css";
import Landing from './Landing.js'
import Services from "./Services.js";
import AboutUs from "./AboutUs.js";
import Testimonials from "./Testimonials.js";

function FullPage() {
    return (
        <div id="FullPage">
            <Landing />
            <Services />
            <AboutUs />
            <Testimonials />
        </div>
    )
}

export default FullPage;
