import "./Carousel.css"
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { format } from 'date-fns';
import { RiDoubleQuotesR } from "react-icons/ri";
import { Link } from "react-router-dom";
import { IoStar } from "react-icons/io5";



const supabase = createClient("https://lbyqwuvefoyslzlodulq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxieXF3dXZlZm95c2x6bG9kdWxxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ0Njg4OTAsImV4cCI6MjA0MDA0NDg5MH0.9Uf6MsRkSzDpPOXqIXkUbwcnI2lcLryfDUj9iknREqk")

export default function SimpleSlider() {
    const [reviews, setReviews] = useState([]);

    async function getReviews() {
        const { data } = await supabase.from("reviews").select()
        setReviews(data);
    }

    useEffect(() =>{
        getReviews()
    },[])

    console.log("reviews:", reviews)

    let short_list = [];

    if (reviews.length >= 3) {
        let list = reviews.slice(Math.max(reviews.length - 3, 0))
        for (let review of list) {
            console.log("review: ", review)
            if (review.review.length <= 311) {
                short_list.push(review)
            }
        }
    } else if (reviews.length > 0 && reviews.length < 3) {
        for (let review of reviews) {
            if (review.review.length <= 311) {
                short_list.push(review)
            }
        }
    }

    console.log("short list:", short_list)

    return (
        <div id="Carousel">
        {reviews &&
            short_list.map((review) => {
                return(
                    <div className="card">
                        <RiDoubleQuotesR size="80px" className="quote"/>
                        <div className="review">
                            <div className="rating">
                                {review.rating &&
                                    [...Array(parseInt(review.rating))].map(() => {
                                        console.log("review rating: ", typeof review.rating)
                                        return(
                                            <IoStar size="16px" />
                                        )
                                    })
                                }
                            </div>
                            <div>{review.review}</div>
                            <div><strong>{review.first_name}</strong></div>
                            <div>{format(review.created_at, 'MMMM dd, yyyy')}</div>
                        </div>
                    </div>
                )
            })}
        {!short_list &&
            <div className="no-reviews">
                No reviews yet! Come back soon or <Link to="/reviews/new"><u>leave your own!</u></Link>
            </div>
        }
      </div>
    );
  }
