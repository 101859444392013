import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import "./ReviewForm.css";
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
import { IoStar } from "react-icons/io5";


const supabase = createClient("https://lbyqwuvefoyslzlodulq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxieXF3dXZlZm95c2x6bG9kdWxxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ0Njg4OTAsImV4cCI6MjA0MDA0NDg5MH0.9Uf6MsRkSzDpPOXqIXkUbwcnI2lcLryfDUj9iknREqk")

function ReviewForm() {
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null)

    const [sent, setSent] = useState(false)
    const [sendError, setSendError] = useState(false)
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        review: '',
        service: ''
    })

    const handleFormChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    };

    const handleSend = async (e) => {
        e.preventDefault();
        console.log("form data:", formData)
        try {
            const response = await supabase.from('reviews').insert([{
                first_name: formData.first_name,
                last_name: formData.last_name,
                review: formData.review,
                service: formData.service,
                rating: rating
            }]).select()
            console.log("Successfully sent", response)
            setFormData({
                first_name: '',
                last_name: '',
                review: '',
                service: '',
            })
            setRating(null)
            setSent(true);
        } catch (error) {
            console.error("Review failed to send", error)
            setSendError(true);
        }
    };

    useEffect(() => {
        console.log("rating: ", rating)
        console.log("rating type: ", typeof rating)
    }, [rating])
    return(
        <div id="ReviewForm">
            <div className="container">
                <div className="text">
                    <h1>Let us know what you thought of our service:</h1>
                    <div>We greatly appreciate your feedback!</div>
                </div>
            </div>
            <form onSubmit={handleSend}>
                <Link to="/">
                    <img src={logo} alt="logo" className="logo" />
                </Link>
                <div className="row">
                    <div className="input">
                        <label for="first_name">First name:</label>
                        <input
                            placeholder="e.g. John"
                            value={formData.first_name}
                            onChange={handleFormChange}
                            name="first_name"
                            type="text"
                            required/>
                    </div>
                    <div className="input">
                        <label for="last_name">Last name:</label>
                        <input
                            onChange={handleFormChange}
                            placeholder="e.g. Smith (optional)"
                            value={formData.last_name}
                            name="last_name"
                            type="text" />
                    </div>
                </div>
                <div className="row">
                    <div className="input">
                        <label for="service">Which service did you receive:</label>
                        <select
                            onChange={handleFormChange}
                            value = {formData.service}
                            name="service"
                            required>
                                <option selected>Select service</option>
                                <option value="Garage Door Opener">Garage Door Opener</option>
                                <option value="Springs and Off-Track">Springs and Off-Track</option>
                                <option value="Garage Door">Garage Door</option>
                                <option value="Repairs">Repairs</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="input">
                        <label>Give us a rating:</label>
                        <div className="rating">
                            {[...Array(5)].map((star, index) => {
                                const currentRating = index + 1;
                                    return(
                                        <label>
                                            <input
                                                type="radio"
                                                name="rating"
                                                value={currentRating}
                                                onClick= {() => setRating(currentRating.toString())}
                                            />
                                                <IoStar
                                                    class="star"
                                                    size= {30}
                                                    color= {currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                                    onMouseEnter={() => setHover(currentRating)}
                                                    onMouseLeave={() => setHover(null)}
                                                />
                                        </label>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="input">
                        <label for="review">Tell us what you think:</label>
                        <textarea
                            onChange={handleFormChange}
                            type="text"
                            name="review"
                            value={formData.review} />
                    </div>
                </div>
                <button type="submit">Submit</button>
                {sent && (
                    <div className="submit-message">
                        <em>Review submitted. Thank you for your feedback!</em>
                    </div>
                )}
                {sendError && (
                <div className="submit-message">
                    <em>Review failed to submit. Please try again.</em>
                </div>
                )}
            </form>
        </div>
    )
}

export default ReviewForm;
